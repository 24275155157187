import { useState } from 'react';

export function useModal() {
  const [modalMarsPhoto, setModalMarsPhoto] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [modalApodIntro, setmodalApodIntro] = useState(true);
  
  const [tipoBusca, setTipoBusca] = useState("data")

  return (
    {
      modalMarsPhoto, 
      setModalMarsPhoto,
      modalInfo, 
      setModalInfo,
      modalApodIntro, 
      setmodalApodIntro,
      tipoBusca,
      setTipoBusca,
    }
  );
}
