import React from "react";
import "./index.scss";
import { useStores } from '../../stores/index';
import close_icon from "../../assets/close-window-64.png"


function ModalApod() {

  const {
    modalStore: {
      modalApodIntro, 
      setmodalApodIntro
    },
  } = useStores();

  function handleCloseModal() {
    setmodalApodIntro(!modalApodIntro)
  }


  return (
    modalApodIntro && <div onClick={() => handleCloseModal()} className='modal-apod'>
      <div className="modal-apod-innter-div">
        <div className="div-close-icon">
          <img src={close_icon} alt="close-icon"/>
        </div>
        <span>Olá, tudo bem? Sempre gostei de assuntos relacionados ao universo, talvez, por ser um ambiente misterioso e desconhecido, mas que tambem instiga muitas perguntas. Imagens do universo me inspiram e estimulam minha imaginação e criatividade. Espero que esta imagem, compartilhada pela API da Nasa, possa impressionar e inspirar você também.</span>
      </div>
    </div>
  );
}

export default ModalApod;
