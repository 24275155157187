import { createContext, useContext } from 'react';
import { useModal } from './modalStore';

const storesCtx = createContext(null);

export function useStores() {
  return useContext(storesCtx);
}

export function StoresProvider({ children }) {
  const modalStore = useModal();

  return (
    <storesCtx.Provider value={{
      modalStore,
    }}
    >
      {children}
    </storesCtx.Provider>
  );
}
