import React, { useState } from "react";
import { Suspense } from 'react';
import "./index.scss";
import LoadingScreen from '../../components/loading_screen/LoadingScreen';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ModalApod from "../../components/modal_apod_intro/ModalAPod";
import { useStores } from '../../stores/index';

const Header = React.lazy(async() => import('../../components/header/Header'));
const Footer = React.lazy(async() => import('../../components/footer/Footer'));


function Apod({scrollPosition}) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    handleGetAPOD()
  }, []);

  const [nasaApod, setNasaApod] = useState({});
  const {
    modalStore: {
      modalApodIntro, 
      setmodalApodIntro,
    },
  } = useStores();

  const apiKey = "zhP63zDrsVKEgDQe8cpKJnjRs9DIGIVDgl1e4D1Z"

  async function handleGetAPOD() {
    try {
      const response = await fetch(`https://api.nasa.gov/planetary/apod?api_key=${apiKey}`)
      const data = await response.json()
      setNasaApod(data);
      
    } catch (error) {
      return error
    }
  }

  return (
    <div className='space-container'>
      <Suspense fallback={<div><LoadingScreen/></div>}>
        <Header />
        <div className="space-central-div">
          <h2>Sobre a Nasa APOD - Astronomy Picture of the Day</h2>
          <div className="golden-short-thick-border"></div>
          <span className="span-intro">Um dos sites mais populares da NASA é o Astronomy Picture of the Day. Na verdade, este site é um dos sites mais populares em todas as agências federais americanas. Todo o dia esta API disponibiliza uma imagem Incrível de algo no espaço sideral ou em nossos céus. Disponibilizada pela NASA, ela estrutura as imagens e os metadados associados para que possam ser reaproveitados e consultados por diversos aplicativos.</span>
          <div className="space-central-foto-information">
              <span>Título Imagem: {nasaApod.title}</span>
              <span>Data: {nasaApod.date}</span>
              <span>Direitos: {nasaApod.copyright}</span>
              <span>Fonte: Nasa APOD API</span>
          </div>
          <div className="space-central-inner-div">
          <LazyLoadImage 
            alt={"nasa-apod-img"}
            className='apod-img'
            src={nasaApod.hdurl}
            threshold={100}
            effect={"blur"}
            visibleByDefault={true}
            scrollPosition={scrollPosition}
        />
          </div>
          <div className="apod-description-div">
              <span className="apod-description-title">O que você esta vendo</span>
              <span className="apod-description">{nasaApod.explanation}</span>
          </div>
        </div>
        <ModalApod/>
        <Footer />
      </Suspense>
    </div>
  );
}

export default trackWindowScroll(Apod);
