import React, { useState } from "react";
import { Suspense } from 'react';
import "./index.scss";
import LoadingScreen from '../../components/loading_screen/LoadingScreen';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { useStores } from '../../stores/index';

const Header = React.lazy(async() => import('../../components/header/Header'));
const Footer = React.lazy(async() => import('../../components/footer/Footer'));
const LookIntoMarsPhoto = React.lazy(async() => import("../../components/modal_mars_photo/LookIntoMarsPhoto"));

function Mars({scrollPosition}) {

  const {
    modalStore: {
      modalMarsPhoto, 
      setModalMarsPhoto,
      modalInfo,
      setModalInfo,
      tipoBusca,
      setTipoBusca

    },
  } = useStores();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [formMarsPhotos, setFormMarsPhotos] = useState({
    marsSearchType: "",
    marsCamera: "",
    marsPage:""
  })

  const [marsImages, setMarsImages] = useState([]);
  const apiKey = "zhP63zDrsVKEgDQe8cpKJnjRs9DIGIVDgl1e4D1Z"

  const options = 
  [
    { value: '', label: 'Default - All Cameras', className: 'mars-selection' },
    { value: 'FHAZ', label: 'Front Hazard Avoidance Camera', className: "mars-selection" },
    { value: 'RHAZ', label: 'Rear Hazard Avoidance Camera', className: 'mars-selection' },
    { value: 'MAST', label: 'Mast Camera', className: 'mars-selection' },
    { value: 'CHEMCAM', label: 'Chemistry and Camera Complex', className: 'mars-selection' },
    { value: 'MAHLI', label: 'Mars Hand Lens Imager', className: 'mars-selection' },
    { value: 'MARDI', label: 'Mars Descent Imager', className: 'mars-selection' },
    { value: 'NAVCAM', label: 'Navigation Camera', className: 'mars-selection' },
    { value: 'PANCAM', label: 'Panoramic Camera', className: 'mars-selection' },
    { value: 'MINITES', label: 'Miniature Thermal Emission Spectrometer (Mini-TES)', className: 'mars-selection' },
  ];

  let defaultOption = "";

  function handleChangeDropdown(value) {
    setFormMarsPhotos({ ...formMarsPhotos, marsCamera: value })
    
  }

  async function handleGetMarsPhotos() {

    if(tipoBusca === "sol") {
      try {
        if(formMarsPhotos.marsCamera) {
        const response = await fetch(`https://api.nasa.gov/mars-photos/api/v1/rovers/curiosity/photos?sol=${formMarsPhotos.marsSearchType}&camera=${formMarsPhotos.marsCamera}&api_key=${apiKey}`)  
        const data = await response.json()
        setMarsImages(data.photos);
        }
  
        if(formMarsPhotos.marsPage) {
          const response = await fetch(`https://api.nasa.gov/mars-photos/api/v1/rovers/curiosity/photos?sol=${formMarsPhotos.marsSearchType}&page=${formMarsPhotos.marsPage}&api_key=${apiKey}`)  
          const data = await response.json()
          setMarsImages(data.photos);
        }
  
        if(!formMarsPhotos.marsCamera && !formMarsPhotos.marsPage) {
          const response = await fetch(`https://api.nasa.gov/mars-photos/api/v1/rovers/curiosity/photos?sol=${formMarsPhotos.marsSearchType}&api_key=${apiKey}`)  
          const data = await response.json()
          setMarsImages(data.photos);
        }
        
        console.log(data.photos);
      } catch (error) {
        return error
      }
    } else {
       try {
        if(formMarsPhotos.marsCamera) {
        const response = await fetch(`https://api.nasa.gov/mars-photos/api/v1/rovers/curiosity/photos?earth_date=${formMarsPhotos.marsSearchType}&camera=${formMarsPhotos.marsCamera}&api_key=${apiKey}`)  
        const data = await response.json()
        setMarsImages(data.photos);
        }
  
        if(formMarsPhotos.marsPage) {
          const response = await fetch(`https://api.nasa.gov/mars-photos/api/v1/rovers/curiosity/photos?earth_date=${formMarsPhotos.marsSearchType}&page=${formMarsPhotos.marsPage}&api_key=${apiKey}`)  
          const data = await response.json()
          setMarsImages(data.photos);
        }
  
        if(!formMarsPhotos.marsCamera && !formMarsPhotos.marsPage) {
          const response = await fetch(`https://api.nasa.gov/mars-photos/api/v1/rovers/curiosity/photos?earth_date=${formMarsPhotos.marsSearchType}&api_key=${apiKey}`)  
          const data = await response.json()
          setMarsImages(data.photos);
        }
        
        console.log(data.photos);
      } catch (error) {
        return error
      }
    }

  }

  function handleChange(e) {
    setFormMarsPhotos({ ...formMarsPhotos, [e.target.name]: e.target.value });
  }

  function handleModalImg(image) {
    setModalInfo({...image});
    setModalMarsPhoto(!modalMarsPhoto)
    console.log(image);
    console.log(modalMarsPhoto);
  }

  return (
    <div className='mars-container'>
      <Suspense fallback={<div><LoadingScreen/></div>}>
        <Header />
        <div className="mars-central-div">
          <h2>Sobre Mars Rover Photos</h2>
          <div className="golden-short-thick-border"></div>
          <span className="span-intro">Essa API foi projetada para coletar dados de imagem pelos rovers Curiosity, Opportunity e Spirit da NASA em Marte, e torná-los mais facilmente disponíveis para outros desenvolvedores, educadores e cientistas.</span>
          <div className="mars-central-inputs">
          <div className="mars-div-how-to-use">
             <span>
              Para utilizar esta API, existem várias consultas possíveis que podem ser feitas. As fotos são organizadas pelo sol (rotação ou dia em Marte) em que foram tiradas, contando a partir da data de pouso de cada um dos rovers, Curiosity, Opportunity e Spirit. Por exemplo, uma foto tirada no 1000º sol em Marte pelo rover Curiosity, terá um atributo sol de 1000. Também é possível buscar imagens pela data da Terra em que uma foto foi tirada, no formato "YYYY-MM-DD".
                Além da consulta por data, os resultados também podem ser filtrados pela câmera com a qual foram feitos e as respostas serão limitadas a 25 fotos por chamada. As consultas que devem retornar mais de 25 fotos serão divididas em várias páginas, que podem ser acessadas adicionando o parâmetro de página na consulta.
             </span>
          </div>        
          <form>
            <div className="div-tipo-de-busca">
              <span>Selecione o tipo de data</span>
              <div className="div-buttons-tipo-busca">
                <button type="button" className={tipoBusca === "sol" ? "button-selecionado" : ""} onClick={() => setTipoBusca("sol")}>
                  Sol em Marte
                </button>
                <button type="button" className={tipoBusca === "data" ? "button-selecionado" : ""} onClick={() => setTipoBusca("data")}>
                  Data na Terra
                </button>
              </div>
            </div>
              <div className="mars-div-input">
              <label>
              Digite o Sol em Marte ou data na Terra
              </label>
              <input placeholder="Digite o sol ou data" type="text" name="marsSearchType" onChange={(e) => handleChange(e)}/>
              </div>
              <div className="mars-div-input">
              <label>
              Selecione a Câmera
              </label>
              <Dropdown options={options} value={defaultOption} onChange={(e) => handleChangeDropdown(e.value)} placeholder="Standard - all" controlClassName="mars-dropdown" menuClassName='mars-options' placeholderClassName='mars-placeholder' arrowClassName='mars-arrowClassName'/>
              </div>
              <div className="mars-div-input">
              <label className="label-width">
              Selecione a Pagina (para quantidades maiores que 25 fotos - opcional)
              </label>
              <input className="mars-selecione-a-pagina" placeholder="Selecione a Pagina (opcional)" type="number" name="marsPage" onChange={(e) => handleChange(e)}/>
              </div>
              <button type="button" onClick={handleGetMarsPhotos}>Buscar Imagens</button>
            </form>
          </div>
          <div className="mars-photos-div">
          {marsImages.map((image) => (
            <div className="mars-photos-div-imagem-individual">
                    <LazyLoadImage 
                    className='imagem-projeto'
                    src={image.img_src}
                    threshold={100}
                    effect={"blur"}
                    visibleByDefault={false}
                    scrollPosition={scrollPosition}
                    onClick={() => handleModalImg(image)}
                    />
                    <span>Data Foto: {image.earth_date}</span>
                    <span>Rover: {image.rover.name}</span>
                    <span>Camera: {image.camera.full_name}</span>
            </div>
          ))}
          </div>
        <LookIntoMarsPhoto/>
        </div>
        <Footer />
      </Suspense>
    </div>
  );
}

export default trackWindowScroll(Mars);
