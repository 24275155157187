import React from "react";
import { Suspense } from 'react';
import "./index.scss";
import LoadingScreen from "../../components/loading_screen/LoadingScreen" 

const Header = React.lazy(async() => import('../../components/header/Header'));
const MainSobre = React.lazy(async() => import('../../components/main_sobre/MainSobre'));
const MainResidenciaSoftware = React.lazy(async() => import('../../components/main_residencia_software/MainResidenciaSoftware'));
const Section_Scrum_Cv = React.lazy(async() => import('../../components/main_section_scrum_cv/Section_Scrum_Cv'));
const MainServicos = React.lazy(async() => import('../../components/main_servicos/MainServicos'));
const Footer = React.lazy(async() => import('../../components/footer/Footer'));

const MainIntroduction = React.lazy(() => new Promise(async resolve => {
  const module = await import("../../components/main_intro/MainIntro");  setTimeout(() => resolve(module), 1000);
}));

function Main() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='main-container'>
      <Suspense fallback={<div><LoadingScreen/></div>}>
        <Header />
        <MainIntroduction />
        <MainSobre />
        <MainResidenciaSoftware />
        <Section_Scrum_Cv />
        <MainServicos />
        <Footer />
      </Suspense>
    </div>
  );
}

export default Main;
