import React from "react";
import { Suspense } from 'react';
import "./index.scss";
import LoadingScreen from '../../components/loading_screen/LoadingScreen';

const Header = React.lazy(async() => import('../../components/header/Header'));
const Footer = React.lazy(async() => import('../../components/footer/Footer'));
const ProjetosMap = React.lazy(async() => import('../../components/projetosMap/ProjetosMap'));

export default function Projetos() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='projetos-container'>
      <Suspense fallback={<div><LoadingScreen/></div>}>
        <Header />
        <ProjetosMap/>
        <Footer />
      </Suspense>
    </div>
  );
}
