import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import RotatingReact from "../animated/rotating_react/RotatingReact";
import logo_marcio from "../../assets/logo_marcio_dev_larger.png";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <div className='footer'>
      <div className='navegacao'>
        <h2 onClick={() => navigate("/")}>Sobre</h2>
        <h2 onClick={() => navigate("/projetos")}>Projetos</h2>
        <h2 onClick={() => navigate("/contato")}>Contato</h2>
      </div>
      <div className='div-logo-marcio-dev'>
        <img src={logo_marcio} />
      </div>
      <div className='logo-react'>
        <a href='https://pt-br.reactjs.org/' target='_blank'>
          <RotatingReact className="footer-rotating-react" />
        </a>
        <span>Built from the ground up using React.
          <br></br>
          Lazily loading this page's content while you navigate.
        </span>
      </div>
    </div>
  );
}
