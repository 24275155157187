import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import logo_infinity from "../../assets/marcio_dev_infinity.png";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default function Header() {
  const navigate = useNavigate();

  const options = 
    [
      { value: '/space/apod', label: 'Nasa APOD - Astronomy Picture of the Day', className: "nasa-selection" },
      { value: '/space/mars', label: 'Mars Rover Photos', className: 'nasa-selection' },
    ];

    function handleChangeDropdown(value) {
      navigate(value)
    }

  const defaultOption = "";

  return (
    <div className='header'>
      <div className='header-div-logo'>
        <img src={logo_infinity} alt='logo_infinity'></img>
      </div>
      <div className='header-navigation'>
        <h2 onClick={() => navigate("/")}>Sobre</h2>
        <h2 onClick={() => navigate("/projetos")}>Projetos</h2>
        <h2 onClick={() => navigate("/contato")}>Contato</h2>
        <Dropdown options={options} value={defaultOption} onChange={(e) => handleChangeDropdown(e.value)} placeholder="Nasa APIs" controlClassName="space-dropdown" menuClassName='space-options' placeholderClassName='space-placeholder' arrowClassName='space-arrowClassName'/>
      </div>
    </div>
  );
}
