import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./pages/main/Main";
import Projetos from "../src/pages/projetos/Projetos";
import Contato from "../src/pages/contato/Contato";
import Apod from "./pages/apod/Apod";
import Mars from "./pages/mars/Mars";

export function MyRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Main />}></Route>
      <Route path='/projetos' element={<Projetos />}></Route>
      <Route path='/contato' element={<Contato />}></Route>
      <Route path='/space/apod' element={<Apod />}/>
      <Route path='/space/mars' element={<Mars />}/>
    </Routes>
  );
}
