import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import linkedin_icon from "../../assets/linkedin-square-color-icon.svg";
import gmail_icon from "../../assets/Gmail_icon_2020.svg";
import gitHub_icon from "../../assets/logo-github-squared.svg"
import "./index.scss";
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Contato({scrollPosition}) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='contato-container'>
      <Header />
      <div className='contato-main'>
        <span className="contato-main-first-span">Gostou do meu portifólio ou dos servicos que posso realizar?</span>
        <span>Então entre em contato! Quem sabe posso ajudar!</span>
        <div className='contato-main-gmail-linkedin'>
          <div>
            <a href='https://br.linkedin.com/in/marciojarros' target='_blank'>
            <LazyLoadImage 
              alt={"linkedin-icon"}
              className="paymaster-imagem-projeto"
              src={linkedin_icon}
              threshold={100}
              effect={"blur"}
              visibleByDefault={false}
              scrollPosition={scrollPosition}
              />
              <span>Linkedin</span>
            </a>
          </div>
          <div>
            <a href='malito:marciojarros@gmail.com' target='_blank'>
              <LazyLoadImage 
              alt={"gmail-icon"}
              className="paymaster-imagem-projeto"
              src={gmail_icon}
              threshold={100}
              effect={"blur"}
              visibleByDefault={false}
              scrollPosition={scrollPosition}
              />
              <span>marciojarros@gmail.com</span>
            </a>
          </div>
          <div>
            <a href='https://github.com/mjarros' target='_blank'>
              <LazyLoadImage 
              alt={"gitHub-icon"}
              className="paymaster-imagem-projeto"
              src={gitHub_icon}
              threshold={100}
              effect={"blur"}
              visibleByDefault={false}
              scrollPosition={scrollPosition}
              />
              <span>GitHub</span>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default trackWindowScroll(Contato);