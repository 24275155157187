import "./index.scss"
import meltingLoader from "../../assets/gifs/meltingLoader.gif"

export default function LoadingScreen() {
    return (
        <div className="main-loading-div">
            <span>
                Loading this page's content...
            </span>
            <img src={meltingLoader} alt="melting loader"/>
        </div>
    )
}